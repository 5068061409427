<template>
  <div>
    <van-nav-bar title="资料上报" left-text="返回" left-arrow fixed placeholder @click-left="$router.push('list_man_vote')">
    </van-nav-bar>

    <van-row :gutter="24" style="border: 0px double rgb(228, 228, 228);background: white;    padding: 10px;">
      <van-col :span="24" style="text-align: center;">
        <br />
        <span style="margin-top: 10px;font-weight: 500;">{{data.vppname}}</span>
        <br />
        <br />
        <span style="margin-top: 10px;font-weight: 500;">{{data.cgname}}</span>
      </van-col>
      <van-col :span="24" style="text-align: center;padding-left:30px;padding-right:30px;">
        <a-divider orientation="left"></a-divider>
      </van-col>
      <van-col :span="24" style="text-align: left;margin-left:30px;padding-left: 100px;">
        <div v-for="itema in datac.m_list" v-bind:key="itema.m_guid" style="margin-top: 10px;">
          <span>{{itema.m_item_name}}</span>
          <div v-for="itemb in itema.m_list" v-bind:key="itemb.m_guid" style="width: 90%;">
            {{itemb.m_jcxuhao}}、{{itemb.m_jcname}}
            <div style="text-align: -webkit-center;margin-top: 10px;margin-bottom: 10px;">
              <van-checkbox-group v-model="itemb.m_u_sel" direction="horizontal" style="    display: inline-flex;">
                <van-checkbox name="是">是</van-checkbox>
                <van-checkbox name="否">否</van-checkbox>
              </van-checkbox-group>
            </div>

            <van-field v-model="itemb.m_u_note" label="备注" placeholder="" />
            <div style="color:#8d8d8d">{{itemb.m_note}}</div>
          </div>
        </div>
      </van-col>
      <van-col :span="24" style="text-align: left;margin-bottom:30px;">
      </van-col>
      <van-col v-if="!istijao" :span="24" style="text-align: center;margin-bottom:30px;">
        <van-button type="warning" @click="on_save_tijiao(0)" style="margin-right: 10px;">
          仅保存
        </van-button>

        <van-button type="primary" icon="check-circle" :loading="iconLoading" @click="on_save_tijiao(1)" style="margin-right: 10px;">
          直接提交
        </van-button>

        <van-button type="danger" icon="check-circle" :loading="iconLoading" @click="showConfirmDel(data)" style="margin-right: 10px;">
          删除记录
        </van-button>

      </van-col>
      <van-col :span="24" style="text-align: left;margin-bottom:30px;">
      </van-col>
    </van-row>

  </div>
</template>
<script> 
import { Dialog, Notify } from 'vant';
import { jn_scuser_com } from "@/api/jn_scuser_com.js";
import { jn_scuser_man_vote } from "@/api/jn_scuser_man_vote.js";
export default {
  data() {
    return {
      loading_sk: true,
      iconLoading: false,
      sdata: {},
      data: {},
      datac: {},
      istijao: false,
      check_isok_info: '',
      check_isok: false,
    };
  },
  created() {
    this.loading_sk = true;
    this.sdata.guid = jn_scuser_com.getQueryVariable("guid");
    this.jn_load();
  },
  methods: {
    tomyhome() {
      this.$router.push('/list_man_vote');
    },
    jn_load() {
      jn_scuser_man_vote.get_record_info({ guid: this.sdata.guid }).then(response => {
        console.log("jn_scuser_com.getinfo=" + response.data);
        this.data = response.data.mydata;
        this.datac = response.data.mydata_sub;
        this.istijao = true;
        if (this.data.vrpischeck == 0) {
          this.istijao = false;
        }
        this.loading_sk = false;
      });
    },
    on_save_tijiao(ischeckvv) {
      var dd = { guid: this.sdata.guid, vrp_ischeck: ischeckvv, vrp_content: JSON.stringify(this.datac) };
      jn_scuser_man_vote.get_record_tijiao_do(dd).then(response => {
        if (response.data.statusCode === "200") {
          Notify({ type: 'success', message: response.data.message });
          if (response.data.message.indexOf("保存") != -1) {
            this.istijao = false;
          } else {
            this.istijao = true;
            this.tomyhome();
          }
        } else {
          Notify({ type: 'warning', message: response.data.message });
        }
      });
    },
    showConfirmDel(data) {
      Dialog.confirm({
        title: '确认',
        message:
          '确定要删除【' + data.vppname + '】吗 ?',
      })
        .then(() => {
          // on confirm 
          var dd = { guid: data.pkguid };
          jn_scuser_man_vote.get_record_del_do(dd).then(response => {
            if (response.data.statusCode === "200") {
              Notify({ type: 'success', message: response.data.message });
              this.tomyhome();
              return true;
            }
          });
        })
        .catch(() => {
          // on cancel 
        });



    },
  },
};
</script>

<style scoped >
.ycc .ant-descriptions-bordered {
  padding: 16px 12px;
}
.ant-checkbox-wrapper .ant-checkbox-wrapper {
  margin-left: 0px;
}
</style>
